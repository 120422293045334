
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import filterProjectParts from "./components/filter-project-parts.vue";
import FileUpload from "@/components/scope/file-upload.vue";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const base = namespace("base");
const fission = namespace("fission");
const management = namespace("management");
@Component({
  components: {
    filterProjectParts,
    FileUpload
  }
})
export default class BillBatch extends Vue {
  @base.Action getDictionaryList;
  @fission.Mutation setFilterProjectPartsDialog;
  @management.Action getStoreList;
  @fission.Action settlement;
  //计算属性
  get breadData() {
    return [
      { name: t("v210831.fission-marketing-bill"), path: "/management/fission-bill/fission-bill" },
      {
        name: t("v210831.batch-settlement")
      }
    ];
  }
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  tableData = [];
  defaultBannerList: any = [];
  dialogFormVisible = false;
  insertForm = {
    bankVoucherAmount: undefined,
    bankVoucher: undefined,
    fissionOrderList: [],
    paymentSerialNumber: undefined
  };
  twiceRule(rule, value, callback) {
    if (Number(value) !== Number(this.amountCount)) {
      callback(new Error(t("v210831.the-bill-amount")));
    } else {
      callback();
    }
  }
  bankAmountRule = (rule, value, callback) => {
    if (value) {
      this.twiceRule(rule, value, callback);
    } else {
      callback(new Error(t("v210831.please-enter-bank-proof-amont")));
    }
  };
  insertFormRule = {
    bankVoucherAmount: [
      { required: true, message: t("v210831.please-enter-bank-proof-amont"), trigger: "blur" },
      { validator: this.bankAmountRule, trigger: "blur" }
    ],
    bankVoucher: [{ required: true, message: t("v210831.please-upload-payment-proof"), trigger: "blur" }],
    paymentSerialNumber: [{ required: true, message: t("v210831.please-enter-bank"), trigger: "blur" }]
  };
  restaurants = [];
  stateObj = null;
  storeCode = "";
  timeout = null;
  loading = false;
  created() {
    this.init();
  }
  mounted() {}
  get getRouterName() {
    return this.$route.name === "fissionBillStore";
  }
  init() {
    if (this.getRouterName) {
      this.getStoreList({ pageNum: 1, pageSize: 20, storeCode: this.$route.params.storeCode }).then(data => {
        this.stateObj = data.data.list[0];
        this.restaurants = data.data.list;
        // this.getStoreListFun(data.data.list[0].storeName);
      });
    } else {
      this.getStoreListFun();
    }
  }
  getStoreListFun(queryString?) {
    this.getStoreList({ pageNum: 1, pageSize: 20, storeName: queryString || "" }).then(data => {
      this.restaurants = data.data.list;
      this.loading = false;
    });
  }
  remoteMethod(query) {
    if (query !== "") {
      this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      //   this.restaurants = this.list.filter(item => {
      //     return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      //   });
      // }, 200);
      this.getStoreListFun(query);
    } else {
      this.restaurants = [];
    }
  }
  get amountCount() {
    if (this.tableData.length) {
      return this.tableData.reduce((total, currentValue) => {
        console.log("[ total ]", total, currentValue.paymentAmount);
        return (total * 1000 + currentValue.paymentAmount * 1000) / 1000;
      }, 0);
    } else {
      return 0;
    }
  }
  remove(row) {
    // this.loading = true;
    this.tableData = this.tableData.filter(item => {
      return item.fissionOrderNo !== row.fissionOrderNo;
    });
    // this.loading = false;
  }
  handleSelect(item) {
    console.log(item);
    this.tableData = [];
  }
  selectBill() {
    console.log("[ this.stateObj ]", this.stateObj);
    if (!this.stateObj) {
      this.$message.warning(t("v210831.please-select-a-store-first"));
      return;
    }
    let refs = this.$refs.filterProjectParts as any;
    refs.getServer();
    this.setFilterProjectPartsDialog(true);
  }
  billBatch() {
    if (!this.tableData.length) {
      return this.$message.warning(t("v210831.please-select-a-bill"));
    }
    console.log("[  ]");
    this.insertForm.fissionOrderList = this.tableData.map(item => {
      return item.fissionOrderNo;
    });
    this.dialogFormVisible = true;
  }
  handleInsert() {
    let refs = this.$refs["insertForm"] as any;
    refs.validate(valid => {
      if (valid) {
        let data = {
          ...this.insertForm
        };
        this.settlement(data).then(res => {
          this.$message.success(t("v210831.settlement-succeeded"));
          this.dialogFormVisible = false;
          this.$router.push("/management/fission-bill/fission-bill");
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  getArrList(arr) {
    this.loading = true;
    let newArr = this.tableData.concat(arr);
    var result = [];
    var obj = {};
    for (var i = 0; i < newArr.length; i++) {
      if (!obj[newArr[i].fissionOrderNo]) {
        result.push(newArr[i]);
        obj[newArr[i].fissionOrderNo] = true;
      }
    }
    console.log("[  ]", result);
    this.tableData = result;
    this.loading = false;
  }
  // / ////
}
