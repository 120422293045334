
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
const fission = namespace("fission");
const category = namespace("parts-category");
const service = namespace("service-item");
const base = namespace("base");
@Component({})
export default class FilterProjectParts extends Vue {
  @fission.State filterProjectPartsDialog;
  @fission.Mutation setFilterProjectPartsDialog;
  @fission.Action getServiceItemPage;
  @fission.Action getPartsPage;
  @category.Action getCategoryList;
  @service.Action queryTwoLevelClassList;
  @fission.Action queryOpReceiptPage;
  @base.Action getDictionaryList;
  @Prop({ type: String, default: "" })
  state: string;
  @Prop({ type: String, default: "" })
  storeCode: string;
  //计算属性
  // dialog = false;
  dayjs = dayjs;
  isIndeterminate = false;
  formInline = {
    user: "",
    region: ""
  };
  tableData = [];
  checkedCities = [];
  get getState() {
    return this.state;
  }
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  chechedAll = false;
  multipleSelectionArr = [];
  CategoryCodeList = [];
  getPushMoney = "project";
  searchForm = {
    time: undefined,
    pageNum: 1,
    pageSize: 1000,
    settlementStatus: "20",
    startPaymentTimeStr: undefined,
    endPaymentTimeStr: undefined,
    storeName: undefined,
    storeCode: undefined
  };
  created() {
    this.searchForm.storeName = this.state;
    this.searchForm.storeCode = this.storeCode || this.$route.params.storeCode || "";
    this.getPushMoney = this.$route.params.type;
    this.init();
  }
  mounted() {}
  async init() {
    this.getDictionaryList("fission_settlement_status").then(data => {
      this.CategoryCodeList = data;
    });
    this.getServer();
    if (this.$route.query.storeCode) {
      this.searchForm.storeCode = this.$route.query.storeCode;
    }
  }
  handleChangeParts() {
    console.log("[ 1 ]", 1);
  }
  wouldCheck(row, index) {
    if (row.hasCommission !== "true") {
      return true;
    } else {
      return false;
    }
  }
  async getServer() {
    let params = {
      ...this.searchForm,
      startPaymentTimeStr: this.searchForm.time ? dayjs(this.searchForm.time[0]).format("YYYYMMDDHHmmss") : undefined,
      endPaymentTimeStr: this.searchForm.time
        ? dayjs(this.searchForm.time[1])
            .add(1, "day")
            .subtract(1, "second")
            .format("YYYYMMDDHHmmss")
        : undefined,
      storeName: this.state,
      storeCode: this.storeCode || this.$route.params.storeCode || ""
    };
    const res = await this.queryOpReceiptPage(params);
    // this.tableData = [{}, {}];
    this.tableData = res.data.list;
  }
  addLeafFlag(list) {
    const f = function(list) {
      list.forEach(item => {
        if (!item.children || item.children.length < 1) {
          delete item.children;
        } else {
          f(item.children);
        }
      });
    };
    f(list);
    return list;
  }
  handleClose() {
    this.setFilterProjectPartsDialog(false);
    this.searchForm.time = undefined;
  }
  iAmSure() {
    this.$emit("getArr", this.multipleSelectionArr);
    this.setFilterProjectPartsDialog(false);
  }
  onSubmit() {
    this.getServer();
  }
  onReset(formName) {
    this.$nextTick(() => {
      this.searchForm.time = undefined;
      this.getServer();
    });
  }
  checkChange(val) {
    let tableRef = this.$refs.multipleTable as any;
    tableRef.toggleAllSelection();
    this.checkedCities = val ? this.tableData : [];
    this.isIndeterminate = false;
  }
  handleSelectionChange(selection) {
    this.multipleSelectionArr = selection;
    this.chechedAll = selection.length ? true : false;
    let checkedCount = selection.length;
    this.chechedAll = checkedCount === this.tableData.length;
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;
  }
  selectAll(selection) {
    this.chechedAll = selection.length ? true : false;
  }
}
